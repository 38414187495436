<template>
  <div class="referrals">
    <div class="filters">
      <div class="search">
        <label for="search">Cerca</label>
        <input id="search" type="text" name="" placeholder="Nome, Utente CVing" @input="filterSearch($event)">
      </div>

      <div class="date-box">
        <label for="date-from">Data inizio</label>
        <input id="date-from" type="date" @input="dateFromChanged($event)">
      </div>

      <div class="date-box">
        <label for="date-to">Data fine</label>
        <input id="date-to" type="date" @input="dateToChanged($event)">
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>
    </div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th v-for="header of headers" :key="header" class="orderable" @click="changeOrder(header)">
              <div class="">
                <span>{{ header }}</span>
                <font-awesome-icon v-if="filters.order.field == header" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="log in getFilteredRows()" :key="log.id">
            <td><span>{{ dayjs(log.data).format('DD/MM/YYYY') }}</span></td>
            <td><span>{{ log.referenziatore }}</span></td>
            <td><span>{{ log.codiceFiscale }}</span></td>
            <td><span>{{ log.residenza }}</span></td>
            <td><span>{{ log.lordoRitenuta ?? 0 }}</span></td>
            <td><span>{{ log.nettoRitenuta ?? 0 }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <floating-button :menu="false" :tooltip="'Scarica i report'" @action="getCSV()" />
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/it'
import { reportbackoffice, sbapibackoffice } from '@/api'
import csvDownload from 'json-to-csv-export'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { reactive, ref } from '@vue/reactivity'
import { inject, onMounted } from '@vue/runtime-core'
import dayjs from 'dayjs'

export default {
  setup() {
    let references = ref([])
    const filters = reactive({
      order: {
        field: 'Data di sblocco',
        asc: false
      },
      search: [],
      dateFrom: null,
      dateTo: null
    })
    const headers = ['Data di sblocco', 'Referenziatore', 'Codice fiscale', 'Residenza', 'Lordo ritenuta', 'Netto ritenuta']
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const toast = inject('$toast')

    /**
		 * Set the order condition for the table
		 * @param {number} orderBy - the column to order by
		 */
    const changeOrder = (orderBy) => {
      if (filters.order.field == orderBy) {
        filters.order.asc = !filters.order.asc
      } else {
        filters.order.field = orderBy
        filters.order.asc = true
      }
    }

    /**
		 * manage the search input
		 * @param {Event} event - the oninput input event
		 */
    const filterSearch = ({ target }) => {
      filters.search = target.value ? target.value.split(' ') : []
    }

    const getFilteredRows = () => {
      const rows = references.value
        .filter((f) => {
          // if the filter is empty each row is allowed
          if (!filters.search.length) return true

          let allFiltersArePresentInRow = true

          for (const filter of filters.search) {
            let n = f.referenziatore.toLowerCase().includes(filter.toLowerCase())
            let u = f.codiceFiscale.toLowerCase().includes(filter.toLowerCase())
            let r = f.residenza.toLowerCase().includes(filter.toLowerCase())

            allFiltersArePresentInRow = allFiltersArePresentInRow && (n || u || r)
            if (!allFiltersArePresentInRow) break
          }

          return allFiltersArePresentInRow
        })
        .filter(({ date }) => (!filters.dateFrom ? true : date >= filters.dateFrom))
        .filter(({ date }) => (!filters.dateTo ? true : date <= filters.dateTo))
        .sort(orderByColumnValues)

      return rows
    }

    const orderByColumnValues = (a, b) => {
      switch (filters.order.field) {
        
        case headers[0]:
          if (a.data < b.data) return filters.order.asc ? -1 : 1
          if (a.data > b.data) return filters.order.asc ? 1 : -1
          break

        case headers[1]:
          if ((a.referenziatore).toLowerCase() < (b.referenziatore).toLowerCase()) return filters.order.asc ? -1 : 1
          if ((a.referenziatore).toLowerCase() > (b.referenziatore).toLowerCase()) return filters.order.asc ? 1 : -1
          break

        case headers[2]:
          if (a.codiceFiscale.toLowerCase() < b.codiceFiscale.toLowerCase()) return filters.order.asc ? -1 : 1
          if (a.codiceFiscale.toLowerCase() > b.codiceFiscale.toLowerCase()) return filters.order.asc ? 1 : -1
          break

        case headers[3]:
          if (a.residenza.toLowerCase() < b.residenza.toLowerCase()) return filters.order.asc ? -1 : 1
          if (a.residenza.toLowerCase() > b.residenza.toLowerCase()) return filters.order.asc ? 1 : -1
          break

        case headers[4]:
          if (a.lordoRitenuta < b.lordoRitenuta) return filters.order.asc ? -1 : 1
          if (a.lordoRitenuta > b.lordoRitenuta) return filters.order.asc ? 1 : -1
          break

        case headers[5]:
          if (a.nettoRitenuta < b.nettoRitenuta) return filters.order.asc ? -1 : 1
          if (a.nettoRitenuta > b.nettoRitenuta) return filters.order.asc ? 1 : -1
          break

        default:
          return 0
      }
      return 0
    }

    onMounted(async () => {
      navbar.title = 'Referenziatori'
      
      spinner.show()
      sbapibackoffice
        .get('/accounting/referralpayout', {
          withCredentials: true
        })
        .then((response) => {
          references.value = response.data
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => spinner.hide())

      spinner.hide()
    })
    const getCSV = () =>{
      spinner.show()
      reportbackoffice.get('/accounting/csv?reportType=referralPayout')
        .then((response) => {
          console.log(response)
          let array = response.data.split('\r\n')
          let header = JSON.parse(JSON.stringify(array[0])).split(',')
          array.shift() 

          let dataFormatted = []
          array.forEach((el, index)=> {
            let data = el.split(',')
            data.forEach((record, i)=>{
              let k = header[i]
              if(dataFormatted[index]) {
                dataFormatted[index][k] = record
              } else {
                dataFormatted.push({
                  [k] :record
                })
              }
            })
          })
          const dataToConvert = {
            data: dataFormatted,
            filename: 'referenziatori__'+moment().format('DD-MM-YY'),
            delimiter: ',',
            headers: header
          }
          console.log(dataToConvert)
          csvDownload(dataToConvert)
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto,'+error)
        })
        .finally(() => spinner.hide())
    }

    return {
      references,
      filters,
      headers,
      spinner,
      navbar,
      changeOrder,
      filterSearch,
      getFilteredRows,
      dayjs,
      getCSV
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../../assets/scss/referrals';
@use '../../assets/scss/table';
.referrals table thead,
.referrals table thead tr:first-child,
.referrals table thead tr:first-child th{
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.referrals table tr th:first-child,
.referrals table .table-body tr td:first-child{
  padding-left:1.5rem!important;
}
.text-xs{
  font-size:13px;
  margin-top:4px;
}

table {
  th {
    &:nth-child(1) {
      width: 100px;
    }
  }
}
</style>
